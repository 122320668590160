<template>
  <div class="passangerInfoBackground">
    <v-row no-gutters align="center">
      <span class="passanger"
        >{{ "passanger_label" | localize }} №{{ index }}</span
      >
      <v-row no-gutters justify="end">
        <!-- <span class="priceInfo">{{ passangerInfo.price }} грн</span> -->
        <div
        v-if="index > 1"
          class="deleteIcon"
          style="cursor: pointer"
          @click="$emit('deletePassanger', passangerInfo.id)"
        />
      </v-row>
    </v-row>
    <div class="sectionLine" style="margin: 20px 0px" />
    <v-row no-gutters align="center">
      <v-col
        class="py-0"
        :style="$vuetify.breakpoint.smAndDown ? '' : 'padding-right: 10px'"
        cols="12"
        xl="6"
        lg="6"
        md="6"
        sm="12"
      >
        <v-text-field
          hide-details
          outlined
          dense
          height="48px"
          style="
            border-radius: 10px;
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            margin-bottom: 20px;
          "
          :label="'first_name_label' | localize"
          :placeholder="'type_here' | localize"
          color="#144FA9"
          v-model="passangerInfo.first_name"
          :error-messages="firstNameError"
        />
      </v-col>
      <v-col
        class="py-0"
        :style="$vuetify.breakpoint.smAndDown ? '' : 'padding-right: 10px'"
        cols="12"
        xl="6"
        lg="6"
        md="6"
        sm="12"
      >
        <v-text-field
          hide-details
          outlined
          dense
          height="48px"
          style="
            border-radius: 10px;
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            margin-bottom: 20px;
          "
          :label="'last_name_label' | localize"
          :placeholder="'type_here' | localize"
          color="#144FA9"
          v-model="passangerInfo.last_name"
          :error-messages="lastNameError"
        />
      </v-col>
      <v-col
        class="py-0"
        :style="$vuetify.breakpoint.smAndDown ? '' : 'padding-right: 10px'"
        cols="12"
        xl="6"
        lg="6"
        md="6"
        sm="12"
      >
        <v-text-field
          hide-details
          outlined
          dense
          height="48px"
          style="
            border-radius: 10px;
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            margin-bottom: 20px;
          "
          :label="'phone_number_label' | localize"
          :placeholder="'type_here' | localize"
          color="#144FA9"
          v-mask="'+###############'"
          v-model="passangerInfo.phone_number"
          :error-messages="phoneNumberError"
        />
      </v-col>
      <v-col
        class="py-0"
        :style="$vuetify.breakpoint.smAndDown ? '' : 'padding-right: 10px'"
        cols="12"
        xl="6"
        lg="6"
        md="6"
        sm="12"
      >
        <v-text-field
          hide-details
          outlined
          dense
          height="48px"
          style="
            border-radius: 10px;
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            margin-bottom: 20px;
          "
          label="Email"
          :placeholder="'type_here' | localize"
          color="#144FA9"
          v-model="passangerInfo.email"
        />
      </v-col>
      <v-col
        class="py-0"
        :style="$vuetify.breakpoint.smAndDown ? '' : 'padding-right: 10px'"
        cols="12"
        xl="6"
        lg="6"
        md="6"
        sm="12"
      >
        <v-autocomplete
          outlined
          dense
          height="48px"
          style="
            border-radius: 10px;
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            margin-bottom: 20px;
          "
          :label="'passanger_type_label' | localize"
          :placeholder="'type_here' | localize"
          color="#144FA9"
          hide-details
          v-model="passangerInfo.type"
          :items="Object.values(passangerTypes)"
          :item-text="(item) => item?.translations?.title"
          :item-value="'id'"
        />
      </v-col>
      <v-col
        class="py-0"
        :style="$vuetify.breakpoint.smAndDown ? '' : 'padding-right: 10px'"
        cols="12"
        xl="6"
        lg="6"
        md="6"
        sm="12"
      >
        <v-text-field
          outlined
          dense
          height="48px"
          style="
            border-radius: 10px;
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            margin-bottom: 20px;
          "
          label="Ціна"
          :placeholder="'type_here' | localize"
          color="#144FA9"
          hide-details
          v-model="passangerInfo.price"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength, email } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],
  data: () => ({
    passangerInfo: {
      first_name: "",
      last_name: "",
      phone_number: "",
      email: "",
      type: "",
    },
  }),
  props: {
    index: {
      require: true,
    },
    touch: {
      require: false,
    },
    passangerTypes: {
      require: true,
    },
    passangerInfo: { require: true },
  },
  validations: {
    passangerInfo: {
      first_name: {
        required,
      },
      last_name: {
        required,
      },
      phone_number: {
        required,
        minLength: minLength(11),
      },
      email: {
        required,
        email,
      },
      // type: {
      //   required,
      // },
    },
  },
  // mounted() {
  //   this.passangerInfo = this.passanger;
  //   this.setPrice();
  // },
  methods: {
    // setPrice() {
    //   let initialValue = 0;
    //   let discountSum = this.passangerInfo.discounts.reduce(
    //     (accumulator, item) =>
    //       accumulator + (item.price_before - item.price_after),
    //     initialValue
    //   );
    //   this.passangerInfo.price =
    //     this.passangerInfo.full_price -
    //       discountSum -
    //       (this.passangerInfo.full_price - discountSum) *
    //         (this.passangerTypes.find(
    //           (type) => type.id == this.passangerInfo.type
    //         )?.amount /
    //           100) || this.passangerInfo.full_price - discountSum;
    // },
    checkPrice() {
      let passanger = this.passangerInfo;
      if (passanger.type !== "") {
        let discount = this.passangerTypes.find(
          (discount) => discount.id == passanger.type
        );
        if (discount.amount_type == "Percent") {
          passanger.price =
            passanger.full_price -
            passanger.full_price * (discount.amount / 100);
        } else {
          passanger.price = passanger.full_price - discount.amount;
        }
      } else {
        passanger.price = passanger.full_price;
      }
      this.passangerInfo = passanger;
    },
  },
  computed: {
    firstNameError() {
      const errors = [];
      let field = this.$v.passangerInfo.first_name;
      if (!field.$dirty) {
        return errors;
      }
      if (!field.required) {
        errors.push("");
      }
      return errors;
    },
    lastNameError() {
      const errors = [];
      let field = this.$v.passangerInfo.last_name;
      if (!field.$dirty) {
        return errors;
      }
      if (!field.required) {
        errors.push("");
      }
      return errors;
    },
    phoneNumberError() {
      const errors = [];
      let field = this.$v.passangerInfo.phone_number;
      if (!field.$dirty) {
        return errors;
      } else if (!field.required) {
        errors.push("");
      }
      if (!field.minLength) {
        errors.push("");
      }
      return errors;
    },
    // typeError() {
    //   const errors = [];
    //   let field = this.$v.passangerInfo.type;
    //   if (!field.$dirty) {
    //     return errors;
    //   }
    //   if (!field.required) {
    //     errors.push("");
    //   }
    //   return errors;
    // },
  },
  watch: {
    touch: {
      handler() {
        if (this.touch) {
          this.$v.$touch();
          console.log(this.$v);
          if (this.$v.$invalid) {
            console.log("WORK INVALID");
            this.$emit("invalidData");
          } else {
            console.log("WORK VALID");
            this.$emit("validData");
          }
        }
      },
    },
    "passangerInfo.type": {
      handler() {
        this.checkPrice();
      },
    },
  },
};
</script>

<style scoped>
.passangerInfoBackground {
  padding: 16px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
}
.passanger {
  color: #1b1b1b;
  font-family: "MacPaw Fixel";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.priceInfo {
  color: #4b5262;
  font-size: 16px;
  font-weight: 600;
}
.deleteIcon:hover {
  background-color: #df3a3a;
}
</style>
<style>
.v-text-field--outlined.v-input--dense .v-label {
  top: 14px;
}
.v-text-field--outlined.v-input--dense .v-label--active {
  top: 10px !important;
}
</style>